<template>
  <div>
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
          ></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <el-button type="primary" round @click="createProject"
      >创建项目</el-button
    >
    <div>
      <el-row>
        <el-col :span="8" v-for="(item, index) in projectList" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-button
                style="float: left; padding: 0 0 10px"
                type="text"
                @click="flushRouter(item.Id)"
                >{{ item.ProjectName }}</el-button
              >
            </div>
            <div class="text item">
              时间：{{ item.StartTime }} - {{ item.EndTime }}
            </div>
            <div class="text item">
              状态：{{ item.IsEnable ? "使用中" : "已归档" }}
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="项目配置"
      :visible.sync="projectFormVisible"
      v-model="projectFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <div
          v-for="(ite, indexTo) in formItems"
          :key="indexTo"
          style="margin-left: 5%"
        >
          <div>{{ ite.DdItemContentValue }}</div>
          <el-form-item
            v-for="(item, index) in ite.Children"
            :key="index"
            :label="item.DdItemContentValue"
            label-width="150px"
          >
            <template v-if="item.DdItemTypeValue === 'radio'">
              <el-radio-group v-model="form[item.DdItemContentKey].value">
                <el-radio
                  v-for="(option, optionIndex) in item.Children"
                  :key="optionIndex"
                  :label="option.DdItemContentKey"
                >
                  {{ option.DdItemContentValue }}
                </el-radio>
              </el-radio-group>
            </template>
            <template v-if="item.DdItemTypeValue === 'checkbox'">
              <el-checkbox-group v-model="form[item.DdItemContentKey].value">
                <el-checkbox
                  v-for="(option, optionIndex) in item.Children"
                  :key="optionIndex"
                  :label="option.DdItemContentKey"
                >
                  {{ option.DdItemContentValue }}
                </el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-if="item.DdItemTypeValue === 'input'">
              <el-input
                v-model="form[item.DdItemContentKey].value"
                style="width: 40%"
              />
            </template>
            <template v-if="item.DdItemTypeValue === 'radioInput'">
              <el-radio-group v-model="form[item.DdItemContentKey].value">
                <el-radio
                  v-for="(option, optionIndex) in item.Children"
                  :key="optionIndex"
                  :label="option.DdItemContentKey"
                >
                  {{ option.DdItemContentValue }}
                </el-radio>
              </el-radio-group>
              <br />
              <el-input
                v-model="form[item.DdItemContentKey].remark"
                style="width: 40%"
              />
            </template>
            <template v-if="item.DdItemTypeValue === 'picker'">
              <el-date-picker
                v-model="form[item.DdItemContentKey].value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
              />
            </template>
          </el-form-item>
        </div>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form>
    </el-dialog>
<!--	创建项目  -->
	  <el-dialog
			  title="创建项目"
			  :visible.sync="creatFalse"
			  v-model="creatFalse"
			  :close-on-click-modal="false"
			  width="30%"
			  :center = true
	  >
		  <el-form ref="form" :model="createForm" label-position="left" label-width="80px">
			  <el-form-item label="项目名称">
				  <el-input v-model="createForm.ProjectName" style="width: 80%"/>
			  </el-form-item>
			  <el-form-item label="地区">
				  <el-cascader
						  v-model="createForm.AreaList"
						  :options="areaList"
						  @change="handleChange"
				      :props="defaultProps"
						  style="width: 80%"
				  ></el-cascader>
			  </el-form-item>
			  <el-form-item label="项目时间">
				  <el-date-picker
						  v-model="createForm.StartTimeStr"
						  type="daterange"
						  yyyy-mm-dd
						  value-format="yyyy-MM-dd"
						  style="width: 80%"
						  range-separator="至"
						  start-placeholder="开始日期"
						  end-placeholder="结束日期">
				  </el-date-picker>
			  </el-form-item>
		  </el-form>
		  <el-button style="margin-left: 42.5%;margin-top: 20px" type="primary" @click="submitCreateForm">下一步</el-button>
	  </el-dialog>
  </div>
</template>

<script>
import {
  getProjectSettingsItemList,
  addProjectSettings,
  getProjectList,
  getNavigationBarByRoleId,
	getLoopAreaDictionary,
	addInitialProject,
} from "../../api/api";
import Toolbar from "../../components/Toolbar.vue";
import { getButtonList } from "../../promissionRouter";
import { filterAsyncRouter } from "@/router/index";
import router from "@/router";
export default {
  components: { Toolbar },
  data() {
    return {
	    defaultProps: {
		    children: "Children",
		    label: "Name",
		    value: "Code",
		    hasChildren: "HasChildren",
		    checkStrictly:'ture',
	    },
	    createForm:{
		    ProjectName:'',
		    StartTimeStr:'',
		    EndTimeStr:'',
		    AreaList:undefined,
			},
	    creatFalse:false,
      projectFormVisible: false,
      projectSettingsList: [],
      buttonList: [],
      form: {},
      formItems: [],
      projectList: [],
	    areaList:[],
	    options: [],
    };
  },
  methods: {
	  submitCreateForm(){
			let params = {
				ProjectName:this.createForm.ProjectName,
				StartTimeStr:this.createForm.StartTimeStr[0],
				EndTimeStr:this.createForm.StartTimeStr[1],
				AreaList:this.createForm.AreaList,
			};
		  addInitialProject(params).then(res=>{
				if(res.data.Success){
					this.$message({
						message: '创建成功',
						type: 'success'
					});
					this.creatFalse = false;
					this.getDataList()
				}
		  })
		  console.log(this.createForm,'this.createForm')
	  },
	  handleChange(value) {
		  console.log(value,'创建项目-级联选择器');
	  },
		//获取行政区信息分页列表
	  getAreaList() {
        let params = {
					parentId:0,
        };
		  getLoopAreaDictionary(params).then((res) => {
          if (res.data.Success) {
            this.areaList = res.data.Response.Children;
          }
        });
      },
		//创建项目
	  createProject() {
			this.createForm = {}
			this.getAreaList()
        this.creatFalse = true;
	  },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    getDataList() {
      let params = {};
      getProjectList(params).then((res) => {
        if (res.data.Success) {
          this.projectList = res.data.Response;
          console.log(res.data.Response, "项目列表");
        }
      });
    },
    submitForm() {
      const formData = {};
      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          formData[key] = {
            Value: this.form[key].value,
            ParentNamespace: this.form[key].ParentNamespace,
            Remarks: this.form[key].remark,
            DdItemContentKey: this.form[key].DdItemContentKey,
            DdItemContentNamespace: this.form[key].DdItemContentNamespace,
            DdItemTypeValue: this.form[key].DdItemTypeValue,
          };
          let newValueName;
          if (this.form[key].DdItemTypeValue === "picker") {
            newValueName = "ProjectTimeList";
            this.$set(this.form[key], newValueName, {
              projectTimeList: this.form[key].value,
            });
          }
          if (this.form[key].DdItemTypeValue === "cascader") {
            newValueName = "AreaList";
            this.$set(this.form[key], newValueName, {
              areaList: this.form[key].value,
            });
          }
          if (this.form[key].DdItemTypeValue === "checkbox") {
            newValueName = "ExamAntiCheatList";
            this.$set(this.form[key], newValueName, {
              examAntiCheatList: this.form[key].value,
            });
          }
        }
      }
      let params = [];
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const element = formData[key];
          params.push(element);
        }
      }
      let res = {
        ProjectSettingsList: params,
      };
      addProjectSettings(res).then((res) => {
        if (res.data.Success) {
          console.log(res.data.Response);
        }
      });
      console.log("表单数据：", formData);
    },
    getFormData(items) {
      if (items) {
        items.forEach((item) => {
          item.Children.forEach((itemElement) => {
            if (itemElement.Children && itemElement.Children.length > 0) {
              const children = itemElement.Children[0];
              this.$set(this.form, itemElement.DdItemContentKey, {
                value: [],
                remark: children.Remarks,
                DdItemContentKey: itemElement.DdItemContentKey,
                ParentNamespace: children.ParentNamespace,
                DdItemContentNamespace: children.DdItemContentNamespace,
                DdItemTypeValue: children.DdItemTypeValue,
              });
              // if (children.DdItemTypeValue === 'picker'){
              // 	this.$set(this.form[itemElement.DdItemContentKey], 'value', 'projectTimeList');
              // }
              // if (children.DdItemTypeValue === 'cascader'){
              // 	this.$set(this.form[itemElement.DdItemContentKey], 'value', 'areaList');
              // }
              if (children.DdItemTypeValue === "checkbox") {
                console.log(children.ParentNamespace, "parentnamespace");
              }
            } else {
              this.$set(this.form, itemElement.DdItemContentKey, {
                value: [],
                remark: itemElement.Remarks,
                DdItemContentKey: itemElement.DdItemContentKey,
                ParentNamespace: itemElement.ParentNamespace,
                DdItemContentNamespace: itemElement.DdItemContentNamespace,
                DdItemTypeValue: itemElement.DdItemTypeValue,
              });
            }
          });
        });
      }
    },
    getFormDataList() {
      let params = {};
      getProjectSettingsItemList(params).then((res) => {
        if (res.data.Success) {
          this.formItems = res.data.Response.Children;
          this.getFormData(this.formItems);
        }
      });
    },
    getFullYear(createTime) {
      const date = new Date(createTime);
      // 获取年份
      const year = date.getFullYear();
      return year;
    },
    flushRouter(projectId) {
      localStorage.removeItem("router");
      console.log(projectId);
      let params = {};
      getNavigationBarByRoleId(params).then((res) => {
        console.log(res);
        if (res.Success) {
          let data = res;
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(
              /Children/g,
              "children"
            )
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(/Path/g, "path")
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(/Meta/g, "meta")
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(/Title/g, "title")
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(
              /RequireAuth/g,
              "requireAuth"
            )
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(
              /IconCls/g,
              "iconCls"
            )
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(/Id/g, "id")
          );
          data.Response.Children = JSON.parse(
            JSON.stringify(data.Response.Children).replace(/Name/g, "name")
          );
          window.localStorage.router = JSON.stringify(data.Response.Children);
					window.localStorage.removeItem('projectId')
          let getRouter = data.Response.Children; //后台拿到路由
          getRouter = filterAsyncRouter(getRouter); //过滤路由
          router.$addRoutes(getRouter); //动态添加路由
          console.log("路由",getRouter)
          this.$router.replace(
            this.$route.query.redirect
              ? this.$route.query.redirect
              : this.getPresentRouter(getRouter)
          );
          window.localStorage.setItem("projectId", projectId);
	        // window.location.reload();
	        this.$forceUpdate();
        }
      });
    },
    getPresentRouter(getRouter) {
      if (getRouter[0].path == "/SystemManager/QuerySettings") {
        return getRouter[0].path;
      } else {
        for (let i = 0; i < getRouter.length; i++) {
          if (getRouter[i].children.length > 0) {
            for (let a = 0; a < getRouter[i].children.length; a++) {
              if (getRouter[i].children[a].path) {
                return getRouter[i].children[a].path;
              }
            }
          }
        }
      }
    },
  },
  created() {
    this.getDataList();
    setTimeout(() => {
      console.log(this.formItems, "返回数据");
    }, 5000);
    this.getFormDataList();
  },
  mounted() {
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  },
};
</script>
<style lang="stylus" scoped>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.box-card {
	height :200px;
  margin: 20px;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
</style>
